/* Global CSS Styles */

/*  */
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400&family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Square+Peg&display=swap");

/*  */
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans", serif;
  background-color: fff;
  color: #333;
}
h1 {
  font-size: 16px;
  font-weight: 500;
}

/*  */
.logo {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 800;
  margin: 0;
  padding: 0;
}

.default-font {
  font-family: "Noto Sans", serif;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/*  */
* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

/*  */
