/* SignIn.css */

body {
  margin: 0;
  font-family: "Arial", sans-serif;
}

.signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.subtitle {
  font-size: 0.9em;
  text-align: center;
  color: #666;
  max-width: 300px;
  margin-bottom: 20px;
}

.signin-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.signin-form label {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 5px;
}

input {
  padding: 10px 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.btn {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
}

.btn-primary {
  background-color: black;
  color: white;
}

.btn-secondary {
  background-color: #e0e0e0;
  color: black;
}

.btn-apple {
  background-color: black;
  color: white;
}

.error {
  color: red;
  font-size: 0.8em;
  margin-top: 10px;
}

.footer-text {
  font-size: 0.8em;
  color: #666;
  text-align: center;
  margin-top: 20px;
}
